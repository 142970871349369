.user-header {
  text-align: center;
  margin: auto;
  padding: 1em;
}

.user-subheader {
  text-align: center;
  margin: auto;
  padding-bottom: 1em;
}

.user-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-data {
  text-align: center;
  font-size: 0.8em;
  padding-bottom: 1em;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.pending {
  color: #9b870c;
  font-style: italic;
  font-weight:500;
}

.active {
  color: #006400;
  font-weight:500;
}

.scheduling-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
}

.scheduling-item > h4 {
  padding-right: 1em;
  margin: 0;
}

.user-map {
  width: 100%;
}

@media screen and (min-width: 480px) {
  .user-body {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
  }

  .user-data {
    text-align: left;
    padding: 0 5% 1em;
  }

  .data-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .data-item.center {
    justify-content: center;
  }

  .nested {
    padding: 0 1em 1em;
  }

  .data-item > h3,   .data-item > h5  {
    padding-right: 1em;
  }

  .data-item.column {
    flex-direction: column;
    align-items: flex-start;
  }

  .scheduling-item {
    justify-content: left;
  }

  .user-map {
    width: 50%;
  }
}

/* *************** ALIGNMENT *************** */
@media screen and (min-width: 768px) {
  .label {
    width: 40%;
    display: inline-block;
    box-sizing:border-box;
    text-align:right;
  }

  .value {
    width: 60%;
    justify-content: left;
    box-sizing:border-box;
    text-align:left;
    padding-left:1em;
  }
}

.react-switch {
  margin: 1em 0;
}