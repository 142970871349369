.request {
  margin-bottom: 1em;
}

.request > span {
  padding: 3px 0;
}

.component-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-header-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1em;
}

.component-header-details h3 {
  margin: 0 0 5px 0;
}

.component-header-details h5 {
  margin: 0;
}

.component-header-details > i {
  color: var(--main-color-blue);
  padding: 0.5em;
  font-size: 2em;
}

.component-header-right {
  font-style: italic;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-header-right > i {
  padding: 0.5em;
  font-size: 1em;
  color: var(--disabled-background);
}

.request-data-header {
  margin: 1em 0 0.5em;
}

.request-response-btn {
  display: block;
  margin: 1em auto;
}

.accept-request-btn {
  color: var(--background-white);
}

.deny-request-btn {
  color: var(--main-color-blue);
}

.approved {
  color: var(--success-green);
  font-style: italic;
}

.denied {
  color: var(--error-red);
  font-style: italic;
}

@media screen and (min-width: 480px) {
  .request-response-btn {
    margin: 0 auto;
  }

  .component-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}