.search-page {
  display: flex;
  margin-top: 2em;
}

.mobile {
  flex-direction: column;
}

.search-filters-column {
  flex: 50%;
  text-align: center;
}

.search-filters-column-hidden {
  margin-bottom: 1em;
}

.mobile > .search-filters-column-hidden {
  margin: 0 1em 1em 1em;
}

@media screen and (min-width: 800px) {
  .search-filters-column {
    flex: 20%;
  }
}

.search-data-column {
  flex: 80%;
  padding: 0 1em;
}

.search-results {
  margin-top: 1em;
}

.box-shadow {
  padding: 1em;
  border: 1px solid var(--box-shadow-gray);
  box-shadow:  0px 1px 3px var(--box-shadow-gray),
  -3px 1px 5px var(--box-shadow-gray), 3px 1px 5px var(--box-shadow-gray);
}

.no-results {
  font-style: italic;
  text-align: center;
}

.slider {
  width: 80%;
  margin: 1em auto 2em;
}

.input-range__track--active {
  background-color: var(--main-color-blue);
}

.input-range__slider {
  background-color: var(--background-white);
  border: 3px solid var(--main-color-blue);
}

.input-range__label-container {
  color: var(--font-black);
}

.select {
  width: 200px;
  border-radius: 0;
  border: 1px solid var(--box-shadow-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  position: relative;
  background: var(--background-white);
}

.select.small {
  width: 70px;
}

.select select {
  background: transparent;
  border: none;
  padding: 0 2em 0 1em;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 3em;
  width: 100%;
}

.select > i {
  width: 20px;
  position: absolute;
  right: 5px;
  pointer-events: none;
  color: var(--disabled-background);
}

.checkbox-container {
  position: relative;
  padding: 0 1em 1em calc(10px + 1em);
  margin: 0.5em;
}

.checkbox-container.inline {
  position: relative;
  padding: 0 1em 0 calc(10px + 1em);
}

.checkbox-container input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 4px;
  height: 10px;
  width: 10px;
  opacity: 0;
  z-index: 1;
  margin: 0;
}

.checkbox {
  position: absolute;
  left: 0;
  top: 4px;
  height: 10px;
  width: 10px;
  background-color: var(--background-white);
  border: 1px solid var(--disabled-background);
  cursor: pointer;
}

.checkbox-container input:checked ~ .checkbox {
  background-color: var(--main-color-blue);
}

.checkbox-container input:checked ~ label {
  color: var(--main-color-blue);
}


.checkbox-container input[type="radio"] {
  position: absolute;
  left: 0;
  top: 4px;
  height: 10px;
  width: 10px;
  opacity: 0;
  z-index: 1;
  margin: 0;
}

.radiobox {
  position: absolute;
  left: 0;
  top: 4px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: var(--background-white);
  border: 1px solid var(--disabled-background);
  cursor: pointer;
}

.checkbox-container input:checked ~ .radiobox {
  background-color: var(--main-color-blue);
}

.dropdown-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdown i {
  font-size: 1.5em;
  color: var(--main-color-blue);
  padding: 0.5em;
}

@media screen and (min-width: 480px) {
  .dropdown-container {
    flex-direction: row;
  }

}

.spaced {
  justify-content: space-evenly;
}

.left-icon-container {
  display: flex;
  align-items: center;
}

.left-icon-container > i {
  font-size: 1.5em;
  color: var(--disabled-background);
  padding: 0.5em;
}

.availability-table {
  font-size: 0.75em;
  text-align: center;
  border-spacing: 0;
  display: flex;
  flex-direction: column;
  justify-content: strech;
}

@media screen and (min-width: 480px) {
  .availability-table {
    flex-direction: row;
  }

  .availability-table table {
    height: 100px;
    width: 100%;
  }

  .availability-table table td{
    height: 100%;
  }
}

.availability-table th {
  background-color: var(--main-color-blue);
  color: var(--background-white);
  padding: 10px;
}

.availability-table td {
  background-color: var(--box-shadow-gray);
  padding: 10px;
  word-wrap: break-word;
  align-items: stretch;
  justify-content: stretch;
}

table, .availability-table th, .availability-table td {
  border-collapse: collapse;
}