.app-header-condensed {
  position: relative;
  height: 100px;
  border-bottom: 2px solid var(--main-color-blue);
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 100%;
  margin: auto;
}

@media screen and (min-width: 480px) {
  .app-title {
    font-size: 2em;
  }
}

@media screen and (min-width: 800px) {
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--max-width);
    margin: auto;
  }
}

.app-header-condensed > h1 {
  font-size: 1.5em;
}

.app-title {
  font-weight: 500;
  font-size: 1.5em;
  color: var(--main-color-blue);
}

.app-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.about-us {
  position: absolute;
  top: 1em;
  left: 1em;
}

.app-header > .login {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
}

.login {
  display: flex;
  align-items: center;
}

.icon {
  padding: 0 10px;
}

.round {
  border-radius:100%;
  max-height: 40px;
}